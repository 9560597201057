export default function(){
    if( $('.js-menu-toggle').is(':hidden') ){
        $('.dropdown').mouseover(function(){
            var height;
            height = $(this).children('.sub-menu').first().outerHeight();
            $(this).addClass('open');
            $('.js-sub-menu-bg').addClass('active');
            $('.js-sub-menu-bg').css('height', height);
        });
        $('.dropdown').mouseleave(function(){
            $('.js-sub-menu-bg').css('height', '0');
            $('.js-sub-menu-bg').removeClass('active');
            $(this).removeClass('open');
        });
    }
}
