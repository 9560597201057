import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
    faArrowCircleUp,
    faArrowLeft,
    faArrowRight,
    faCheck,
    faSearch,
    faExternalLinkAlt,
    faPhone,
} from "@fortawesome/free-solid-svg-icons";
import {
    faFacebook,
    faYoutube,
    faTwitter,
    faInstagram,
    faLinkedin
} from "@fortawesome/free-brands-svg-icons";

/**
 * Loads the required Fontawesome icons
 *
 * @see https://fontawesome.com/how-to-use/on-the-web/advanced/svg-javascript-core
 */
export default function() {
    library.add(
        faArrowLeft,
        faArrowRight,
        faArrowCircleUp,
        faCheck,
        faSearch,
        faExternalLinkAlt,
        faPhone,
        faFacebook,
        faYoutube,
        faTwitter,
        faInstagram,
        faLinkedin
    );
    dom.watch();
}
