export default function() {

    $('.js-category-select').on('change',function(){
        var url = $(this).val();
        if(url){
            window.location.href = url;
        }
    });

}
